<template>
  <div class="bg-gray-50 animate-pulse rounded-lg" :style="style" />
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    width?: number | string;
    height?: number;
  }>(),
  {
    width: 50,
    height: 20,
  },
);

const style = computed(() => {
  return {
    width:
      typeof properties.width === "string"
        ? properties.width
        : `${properties.width}px`,
    height: `${properties.height}px`,
  };
});
</script>
